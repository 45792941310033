import { IFormData } from '@interfaces/user';
import { onlyNumbers } from '@utils/onlyNumbers';

interface IPayloadSignup {
  email: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  identifierCpf: string;
  phoneMobile: string;
  identifierCro: string;
  identifierCrm: string;
  specialty: string;
  university: string;
  password: string;
  'password-confirm': string;
  practitionerTitle: string;
}

enum ProfessinalType {
  DOCTOR = '67ba5cd3-efee-43f9-af77-1f73af61992e',
  DENTIST = '72588c36-f245-4ec9-967a-bb640e5f7605',
}

/**
 * Converte o formato do objeto para chamar corretamente o endpoint
 * @param data Data do formulário pelo evento de submit do Form da Design System
 * @returns Data no formato do endpoint
 */
export const formatSignupData = (data: IFormData, isSocial: boolean) => {
  const names = data.stepOne.fullName.split(' ');
  const firstName = names.shift() || '';
  let payloadFormatData: Partial<IPayloadSignup> = {
    email: data.stepOne.email,
    firstName,
    lastName: names.join(' '),
    birthDate: data.stepTwo.birthDate,
    identifierCpf: onlyNumbers(data.stepOne.cpf),
    phoneMobile: onlyNumbers(data.stepTwo.cellPhone),
    practitionerTitle: data.stepTwo.title,
  };
  if (!isSocial) {
    payloadFormatData = {
      ...payloadFormatData,
      password: data.stepTwo.password,
      'password-confirm': data.stepTwo.password,
    };
  }
  if (data.stepOne.isStudent) {
    payloadFormatData = {
      ...payloadFormatData,
      university: data.stepOne.education,
    };
  } else {
    switch (data.stepOne.professionalType) {
      case ProfessinalType.DOCTOR: {
        payloadFormatData = {
          ...payloadFormatData,
          identifierCrm: `${data.stepTwo.professionalRegistry}${data.stepTwo.professionalRegistryUF}`,
          specialty: data.stepTwo.specialty,
        };
        break;
      }
      case ProfessinalType.DENTIST: {
        payloadFormatData = {
          ...payloadFormatData,
          identifierCro: `${data.stepTwo.professionalRegistry}${data.stepTwo.professionalRegistryUF}`,
          specialty: data.stepTwo.specialty,
        };
        break;
      }
      default: {
        payloadFormatData = {
          ...payloadFormatData,
          identifierCrm: `${data.stepTwo.professionalRegistry}${data.stepTwo.professionalRegistryUF}`,
          specialty: data.stepTwo.specialty,
        };
        break;
      }
    }
  }
  return payloadFormatData;
};
