import { IOptions } from '@interfaces/specialty';
import { Box, Text } from '@memed/epiderme';
import { ReactNode } from 'react';
import './specialtySearch.css';

interface ISpecialtyItem {
  children: ReactNode
  handleClick: (option: IOptions) => void
  specialty: IOptions
}

export const SpecialtyItem = ({ children, handleClick, specialty }: ISpecialtyItem) => (
  <Box
    as="button"
    paddingY={2}
    paddingX={3}
    cursor="pointer"
    display="flex"
    gap={3}
    marginTop={2}
    width="100%"
    _hover={{
      background: 'primary.50',
    }}
    onClick={() => handleClick(specialty)}
  >
    <Text lineHeight="base" color="gray.700" fontSize="sm">
      {children}
    </Text>
  </Box>
);
