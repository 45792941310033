export interface IFormData {
  stepOne: {
    professionalType: string;
    education?: string;
    cpf: string;
    fullName: string;
    email: string;
    isStudent: boolean;
  };
  stepTwo: {
    title: ProfessionalTitle;
    professionalRegistry: string;
    professionalRegistryUF: string;
    specialty: string;
    birthDate: string;
    cellPhone: string;
    password?: string;
    agreeToTermsOfUse: boolean;
    university?: string;
  }
}

export interface IStudent {
  universityId: string;
}

export interface IDentist {
  professionalRegistry: string;
  professionalRegistryUF: UF;
  specialtyId: string;
  cns: string;
}

export enum ProfessionalTitle {
  DR = 'Dr.',
  DRA = 'Dra.',
}

export enum ProfileType {
  PROFESSIONAL = 'Profissional de saúde',
  STUDENT = 'Estudante',
}

export enum Professionals {
  DOCTOR = 'Médico',
  DENTIST = 'Dentista',
}

export interface ISpecialty {
  id: string;
  category?: string;
  description: string;
}

export interface IUniversity {
  id: string;
  name: string;
  shortName: string;
}

export interface IUser {
  nome: string;
  sobrenome: string;
  sexo: string;
  board: {
    board_code: string;
    board_number: string;
    board_state: string;
    boards_id: number;
  }
  is_partner_standalone: boolean;
  token: string;
  id: number;
  email: string;
  crm: string;
  status: string;
  total_of_prescripted_drugs: number;
  total_of_prescriptions: number;
  total_of_sms_prescriptions: number;
  cidade: {
    id: string;
    nome: string;
  };
  uf: UF;
  parceiros_id: number;
  especialidade: {
    id: number;
    nome: string;
  };
  estudante: boolean;
}

export type UF =
  | 'AC'
  | 'AL'
  | 'AP'
  | 'AM'
  | 'BA'
  | 'CE'
  | 'DF'
  | 'ES'
  | 'GO'
  | 'MA'
  | 'MT'
  | 'MS'
  | 'MG'
  | 'PA'
  | 'PB'
  | 'PR'
  | 'PE'
  | 'PI'
  | 'RJ'
  | 'RN'
  | 'RS'
  | 'RO'
  | 'RR'
  | 'SC'
  | 'SP'
  | 'SE'
  | 'TO';
