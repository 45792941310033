/* eslint-disable react/jsx-props-no-spreading */
import ContentWrapper from '@components/Template/ContentWrapper';
import ErrorSection from '@components/Template/ErrorSection';
import { Button, Box, Text } from '@memed/epiderme';
import { KcContextBase } from 'keycloakify';
import { ERROR_DATA } from './Error.data';

export function evaluateError(context: KcContextBase) {
  return context.message
    && context.message.summary
    && context.message.type === 'warning'
    && context.pageId !== 'error.ftl';
}

const Warning = ({ context }: { context: KcContextBase.Error }) => (
  <ContentWrapper>
    <ErrorSection {...ERROR_DATA.ERROR_WARNING}>
      <Box as="a" flex={1} href={context.url.loginRestartFlowUrl}>
        <Text>{context.message?.summary}</Text>
        <Button>Voltar</Button>
      </Box>
    </ErrorSection>
  </ContentWrapper>
);

export default Warning;
