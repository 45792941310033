import { onlyNumbers } from '@utils/onlyNumbers';

export const cellPhoneMask = (cellPhone: string) => {
  if (cellPhone) {
    let cloneCellPhone = cellPhone;
    cloneCellPhone = cloneCellPhone.replace(/[^\d]/g, '');
    cloneCellPhone = cloneCellPhone.substring(0, 11);
    if (cloneCellPhone.length > 2) {
      cloneCellPhone = cloneCellPhone.replace(/^(\d{2})(\d*)/, '($1) $2');
    }
    if (cloneCellPhone.length > 9) {
      cloneCellPhone = cloneCellPhone.replace(/^(.*)(\d{4})$/, '$1-$2');
    }
    return cloneCellPhone;
  }
  return cellPhone;
};

export const isValidCellPhone = (cellPhone: string) => {
  const cellPhoneRegex = /^((1[1-9])|([2-9][0-9]))(9[0-9]{3}[0-9]{5})$/;
  return cellPhoneRegex.test(onlyNumbers(cellPhone));
};
