/* eslint-disable react/jsx-props-no-spreading */
import ContentWrapper from '@components/Template/ContentWrapper';
import ErrorSection from '@components/Template/ErrorSection';
import { Button, Box } from '@memed/epiderme';
import { KcContextBase } from 'keycloakify';
import { ERROR_DATA } from './Error.data';

export function evaluateError(context: KcContextBase.Error) {
  return context.message.type === 'error' && context.pageId === 'error.ftl';
}

const Default = ({ context }: { context: KcContextBase.Error }) => (
  <ContentWrapper>
    <ErrorSection {...ERROR_DATA.ERROR_DEFAULT}>
      <Box as="a" flex={1} href={context.url.loginRestartFlowUrl}>
        <Button>Voltar</Button>
      </Box>
    </ErrorSection>
  </ContentWrapper>
);

export default Default;
