import { Center } from '@chakra-ui/react';
import { useFormData } from '@hooks/useFormData';
import { IOptions } from '@interfaces/specialty';
import { Box, InputText, Text } from '@memed/epiderme';
import { ChangeEvent, useEffect, useState } from 'react';
import { Close } from '@components/Icons/Close';
import { ChevronDown } from '@components/Icons/ChevronDown';
import { Highlight, SearchWrapper } from '@components/index';
import { SpecialtyItem } from './SpecialtyItem';

interface ISpecialtySearch {
  showValidation: boolean;
}

const SpecialtySearch = ({ showValidation }: ISpecialtySearch) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedSpecialty, setSelectedSpecialties] = useState<string>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { formData: { current: { stepTwo } } } = useFormData();
  const { specialties } = useFormData();

  const handleChangeSearchTerm = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleClickSpecialties = (specialty: IOptions) => {
    stepTwo.specialty = specialty.value;
    setSelectedSpecialties(specialty.label);
    setShowDropdown(false);
    setSearchTerm('');
  };

  const renderDropdownOrClear = () => {
    if (!selectedSpecialty) {
      return (
        <Box
          cursor="pointer"
          onClick={() => setShowDropdown(true)}
        >
          <ChevronDown w={6} h={6} />
        </Box>
      );
    }
    return (
      <Box
        cursor="pointer"
        onClick={() => {
          setSearchTerm('');
          setSelectedSpecialties('');
          stepTwo.specialty = '';
        }}
      >
        <Close w={6} h={6} />
      </Box>
    );
  };

  const inputValue = selectedSpecialty ? `${selectedSpecialty}` : searchTerm;

  const hasResults = specialties
    ?.filter((specialty) => specialty.label.toLowerCase().includes(searchTerm.toLowerCase()));

  const hasSpecialties = specialties && specialties.length > 0;

  useEffect(() => { if (searchTerm) setShowDropdown(true); }, [searchTerm]);

  const isSpecialtyOnTheList = (value: string) => specialties
    ?.find((specialty) => specialty.label === value) !== undefined;

  return (
    <Box mb={8} position="relative">
      <InputText
        iconLeft="search-loupe"
        placeholder="Digite ou selecione uma especialidade"
        label="Especialidade"
        onChange={handleChangeSearchTerm}
        iconRight={renderDropdownOrClear()}
        value={inputValue}
        showValidation={showValidation}
        externalCustomValidation={[isSpecialtyOnTheList]}
        errors={{
          custom: 'É necessário selecionar uma especialidade na lista',
        }}
        isRequired
      />
      <SearchWrapper showDropdown={showDropdown} onClose={setShowDropdown}>
        {hasSpecialties && !searchTerm && specialties?.map((specialty) => (
          <SpecialtyItem key={specialty.value} handleClick={handleClickSpecialties} specialty={specialty}>
            {specialty.label}
          </SpecialtyItem>
        ))}
        {hasSpecialties && searchTerm && hasResults?.map((specialty) => (
          <SpecialtyItem key={specialty.value} handleClick={handleClickSpecialties} specialty={specialty}>
            <Highlight
              query={searchTerm}
              styles={{ color: 'primary.600', fontWeight: '700' }}
            >
              {specialty.label}
            </Highlight>
          </SpecialtyItem>
        ))}
        {!hasSpecialties && !hasResults && (
          <Center mt={2} py={2}>
            <Text fontSize="sm">Não há resultados para exibir</Text>
          </Center>
        )}
      </SearchWrapper>
    </Box>
  );
};

export default SpecialtySearch;
