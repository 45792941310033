import { useEffect, useRef } from 'react';

declare const window: Window & {
  zE: {
    show: () => void;
    hide: () => void;
    setHelpCenterSuggestions: (data: { search: string }) => void;
  }
};

export const useZendesk = () => {
  const zEInterval = useRef<NodeJS.Timer | null>(null);
  useEffect(() => () => {
    if (zEInterval.current) clearInterval(zEInterval.current);
  }, []);

  const initZendesk = (search: string) => {
    const script = document.createElement('script');
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=0c053a6f-781b-42f2-8759-807e8a655df9';
    script.async = true;
    script.id = 'ze-snippet';
    document.body.appendChild(script);

    zEInterval.current = setInterval(() => {
      if (window?.zE.show) {
        window.zE.show();
        window.zE.setHelpCenterSuggestions({ search });
        if (zEInterval.current) {
          clearInterval(zEInterval.current);
        }
      }
    }, 1500);
  };

  const hideZendesk = () => {
    try {
      window.zE.hide();
    } catch (e) {
      // console.log(e);
    }
  };

  return { initZendesk, hideZendesk };
};
