import { useBreakpoint } from '@chakra-ui/react';
import { useKeycloackContext } from '@hooks/useKeycloakContext';
import {
  Box, Button, Flex, Heading, Text,
} from '@memed/epiderme';
import { KeycloakContextProps } from '@services/keycloak/types/context.type';
import { KcContextBase } from 'keycloakify';
import hero from '../../assets/images/health_professional.webp';
import { WHITE_SECTION_SIZE_2XL, WHITE_SECTION_SIZE_MD, WHITE_SECTION_SIZE_XL } from './Sizes';

interface IChildren { children: React.ReactNode }
interface ITemplate { children: React.ReactNode, hasSignUpCta?: boolean }

const HeroBanner = ({ children }: IChildren) => (
  <Flex
    width={{ base: '0', md: `calc(100% - ${WHITE_SECTION_SIZE_MD})`, '2xl': `calc(100% - ${WHITE_SECTION_SIZE_2XL})` }}
    height="100vh"
    background={`url(${hero}) no-repeat`}
    backgroundSize="cover"
    left={{ md: WHITE_SECTION_SIZE_MD, xl: WHITE_SECTION_SIZE_XL, '2xl': WHITE_SECTION_SIZE_2XL }}
    position="fixed"
    backgroundPosition={{ base: 'top right 20%', lg: 'top right 25%' }}
  >
    {children}
  </Flex>
);

const HeroGradient = () => (
  <Box
    width="100%"
    height="100%"
    background="linear-gradient(180deg, rgba(63, 50, 183, 0) 25.88%, #322289 81.65%)"
    mixBlendMode="multiply"
    position="absolute"
  />
);

const TextWrapper = ({ children }: IChildren) => (
  <Flex marginTop="auto" gap={4} zIndex={2} mb={16} px={{ base: 4, lg: 10 }}>{children}</Flex>
);

const VerticalBar = ({ height }: { height: string }) => (
  <Box
    display={{ base: 'none', lg: 'flex' }}
    width={2}
    height={height}
    bgColor="neutral.50"
  />
);

const WhiteSection = ({ children }: IChildren) => (
  <Flex
    width={{
      base: '100%', md: WHITE_SECTION_SIZE_MD, xl: WHITE_SECTION_SIZE_XL, '2xl': WHITE_SECTION_SIZE_2XL,
    }}
  >
    {children}
  </Flex>
);

const Template = (props: Partial<KeycloakContextProps> & ITemplate) => {
  const breakpoint = useBreakpoint();
  const { kcContext, children, hasSignUpCta } = props;

  const kcContextBase = useKeycloackContext<KcContextBase.Login>(kcContext!);

  const isMobile = breakpoint === 'base' || breakpoint === 'sm';

  return (
    <Flex height="100vh">
      <WhiteSection>
        {children}
      </WhiteSection>
      {!isMobile && (
        <HeroBanner>
          <HeroGradient />
          <TextWrapper>
            <VerticalBar height={hasSignUpCta ? '336px' : '200px'} />
            <Flex flexDir="column" justifyContent="space-around" gap={{ base: 4, '2xl': 0 }}>
              <Heading
                type="title-md"
                variant="serif"
                fontWeight="normal"
                color="neutral.50"
                maxW="500px"
                fontSize={{ base: '32px', '2xl': '40px' }}
              >
                Prescreva mais rápido e com menos custos
              </Heading>
              <Text color="neutral.50" fontSize={{ base: 'md', xl: 'xl' }} lineHeight="base" maxW="680px">
                Com a Memed, além de prescrever medicamentos, você pode fazer pedidos de exames,
                emitir atestados, encaminhamentos e muito mais. A Memed é gratuita e você não precisa
                nem pagar pela assinatura digital.
              </Text>
              {hasSignUpCta && (
                <Box as="a" href={kcContextBase.context.url.registrationUrl} width="168px">
                  <Button
                    variant="outline"
                    w="fit-content"
                    borderColor="white"
                  >
                    Criar minha conta
                  </Button>
                </Box>
              )}
            </Flex>
          </TextWrapper>
        </HeroBanner>
      )}
    </Flex>
  );
};

export default Template;
