import { Flex } from '@memed/epiderme';
import { ReactNode } from 'react';

const ContentWrapper = ({ children }: { children: ReactNode }) => (
  <Flex
    flexDir="column"
    width={{ base: '100%', md: '364px', xl: '460px' }}
    margin="auto"
    paddingX={8}
    paddingY={10}
  >
    {children}
  </Flex>
);

export default ContentWrapper;
