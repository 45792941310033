/**
 * Função que verifica o texto com alguns Regex e indica se ele parece ser um CPF
 * @param value String a validar
 * @returns bool que indica se parecer ser um CPF
 */
export const seemsLikeCpf = (value: string) => {
  const eraseCpfChars = /[\d|.|-]+/g;
  const quantityOfNumber = (value.match(/\d/g) || []).length;
  return value.replace(eraseCpfChars, '').length === 0 && quantityOfNumber === 11;
};
