/* eslint-disable react/jsx-props-no-spreading */
import ContentWrapper from '@components/Template/ContentWrapper';
import HeadingSection from '@components/Template/HeadingSection';
import { useKeycloackContext } from '@hooks/useKeycloakContext';
import {
  Button, Form, IFormData, Text,
} from '@memed/epiderme';
import { InputPasswordChecklist } from '@components/InputPasswordChecklist/InputPasswordChecklist';
import { KeycloakContextProps } from '@services/keycloak/types/context.type';
import { useEffect, useState } from 'react';
import { KcContextBase } from 'keycloakify';
import { useZendesk } from '@hooks/useZendesk';
import { trackPasswordResetPageview } from '@services/tracking/x-ray-tracker';
import { SubmitForm } from '@utils/SubmitForm';

const ForgotPassword = (props: KeycloakContextProps) => {
  const { kcContext } = props;
  const { context: { url } } = useKeycloackContext<KcContextBase.RegisterUserProfile>(kcContext);
  const { initZendesk } = useZendesk();
  const [showValidation, setShowValidation] = useState(false);
  const [isloading, setIsLoading] = useState(false);

  useEffect(() => {
    initZendesk('Senha');
    trackPasswordResetPageview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = ({ isValid, data }: IFormData) => {
    setIsLoading(true);
    if (!isValid) {
      setIsLoading(false);
      setShowValidation(true);
      return;
    }
    setShowValidation(false);
    const dataToSubmit = {
      'password-new': data.password as string,
      'password-confirm': data.password as string,
    };

    const form = new SubmitForm({
      action: url.loginAction,
      method: 'POST',
    });

    form.execute(dataToSubmit);
  };

  return (
    <ContentWrapper>
      <Form onSubmit={onSubmit}>
        <HeadingSection title="Redefinição de senha">
          <Text color="neutral.700" fontSize="sm" fontWeight="medium">
            Crie sua nova senha
          </Text>
        </HeadingSection>
        <InputPasswordChecklist name="password" isRequired label="Senha" mb={8} showValidation={showValidation} />
        <Button w="full" isLoading={isloading} type="submit" mb={8}>Alterar senha</Button>
      </Form>
    </ContentWrapper>
  );
};

export default ForgotPassword;
