import { seemsLikeCpf } from '@utils/seemsLikeCpf';
import { maskCPF } from './maskCPF';

/**
 * Verifica se todos os caracteres são do contexto de CPF (números, traço e ponto),
 * caso não sejam, enquadra como texto sem máscara para ser validado como email
 * @param value String que pode ser CPF ou Email
 * @returns Valor com máscara de CPF aplicada se se encaixar como CPF, ou o valor sem máscara
 */
export const maskLoginHealthcare = (value: string) => {
  if (seemsLikeCpf(value)) {
    return maskCPF(value);
  }
  return value;
};
