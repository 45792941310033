export const ERROR_DATA = {
  ERROR_404: {
    title: 'Opa! Página não encontrada',
    description: 'Essa página não existe mais, por favor, tente novamente.',
    icon: 'document-empty',
  },
  ERROR_COOKIE: {
    title: 'Você precisa ativar os Cookies',
    description:
      'Para entrar, você precisa ativar ou permitir o uso de cookies pela Memed. Caso este erro persista, entre em contato com nosso suporte.',
    icon: 'lock-open',
  },
  ERROR_WARNING: {
    title: 'Opa! Algo requer sua atenção',
    icon: 'warning',
  },
  ERROR_DEFAULT: {
    title: 'Opa! Ocorreu um erro interno',
    description: 'Por favor, tente novamente. Se esse erro persistir, entre em contato com nosso suporte.',
    icon: 'broken-bones',
  },
  ERROR_INFO: {
    title: 'Opa! Ocorreu algum problema',
    description: 'Não se preocupe vamos te redirecionar em alguns segundos.',
    icon: 'heartbeat-heart',
  },
};
