import { createIcon } from '@chakra-ui/icons';

export const AppStore = createIcon({
  displayName: 'AppStore',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M7.5 2.25H16.504C19.4012 2.25 21.75 4.59879 21.75 7.496V16.505C21.75 19.4014 19.401 21.75 16.504 21.75H7.496C4.59879 21.75 2.25 19.4012 2.25 16.504V7.5C2.25 4.60079 4.60079 2.25 7.5 2.25ZM16.504 3.75H7.5C5.42921 3.75 3.75 5.42921 3.75 7.5V16.504C3.75 18.5728 5.42721 20.25 7.496 20.25H16.504C18.5727 20.25 20.25 18.5729 20.25 16.505V7.496C20.25 5.42721 18.5728 3.75 16.504 3.75ZM7.22882 15.5675C7.43433 15.2078 7.89247 15.0829 8.25211 15.2884C8.58178 15.4768 8.71424 15.8774 8.57573 16.2199L8.53119 16.3117L8.21119 16.8717C8.00568 17.2313 7.54754 17.3563 7.1879 17.1508C6.85823 16.9624 6.72577 16.5617 6.86427 16.2192L6.90882 16.1275L7.22882 15.5675ZM13.7643 10.3325C13.5393 10.0395 13.1268 9.95064 12.7973 10.1393C12.4378 10.3452 12.3133 10.8034 12.5192 11.1629L14.2571 14.1976C14.2814 14.2563 14.3131 14.3113 14.3508 14.3613L15.7892 16.8729L15.8457 16.9578C16.0707 17.2508 16.4832 17.3397 16.8127 17.151C17.1722 16.9452 17.2967 16.4869 17.0908 16.1274L16.2505 14.6602H17L17.1018 14.6533C17.4679 14.6036 17.75 14.2899 17.75 13.9102C17.75 13.4959 17.4142 13.1602 17 13.1602H15.3915L13.8208 10.4174L13.7643 10.3325ZM10.6167 6.34963C10.946 6.16064 11.3586 6.24918 11.5839 6.54197L11.6405 6.62683L12.0002 7.25371L12.3593 6.62721C12.5652 6.26783 13.0235 6.14345 13.3829 6.34941C13.7123 6.5382 13.8443 6.93903 13.7054 7.28134L13.6607 7.37304L10.3442 13.1602H12.62C13.0342 13.1602 13.37 13.4959 13.37 13.9102C13.37 14.2899 13.0878 14.6036 12.7218 14.6533L12.62 14.6602H9.06151C9.05625 14.6602 9.05099 14.6603 9.04573 14.6602C9.04285 14.6602 9.03997 14.6602 9.03709 14.6602H7C6.58579 14.6602 6.25 14.3244 6.25 13.9102C6.25 13.5305 6.53215 13.2167 6.89823 13.167L7 13.1602H8.61536L11.1361 8.76158L10.3395 7.37342C10.1333 7.01416 10.2574 6.55579 10.6167 6.34963Z"
    />
  ),
});
