import { useFormData } from '@hooks/useFormData';
import { ProfileType } from '@interfaces/user';
import {
  Button, Form, IFormData, InputCpf, InputEmail, InputText,
  RadioButton, RadioGroup, Select, Text, Flex, Divider,
} from '@memed/epiderme';
import HeadingSection from '@components/Template/HeadingSection';
import { scrollToTop } from '@utils/scroll';
import { ChangeEvent, useState } from 'react';
import { KcContextBase } from 'keycloakify';
import { Google } from '@components/Icons/Google';
import { nameValidation } from '@utils/validations/name';

export type IUrl = {
  url: {
    loginAction: string;
    resourcesPath: string;
    resourcesCommonPath: string;
    loginRestartFlowUrl: string;
    loginUrl: string;
  } & {
    registrationAction: string;
  };
};

type ISignupStepOne = IUrl & Partial<KcContextBase.RegisterCommon>;

const SignupStepOne = ({ url, social }: ISignupStepOne) => {
  const [showValidation, setShowValidation] = useState(false);
  const { formData: { current: { stepOne } } } = useFormData();
  const {
    categories, getSpecialtyById, getUniversities, setStepper,
  } = useFormData();

  const handleGoogleSignupClick = () => {
    if (social && social.providers) {
      const googleProvider = social.providers.find((provider) => provider.providerId === 'google');
      if (googleProvider) {
        window.location.href = googleProvider.loginUrl;
      }
    }
  };

  const handleChangeProfileType = (value: string) => {
    stepOne.isStudent = value === ProfileType.STUDENT;
    if (stepOne.isStudent) getUniversities();
  };

  const handleChangeCpf = (e: ChangeEvent<HTMLInputElement>) => {
    stepOne.cpf = e.target.value;
  };

  const handleChangeFullName = (e: ChangeEvent<HTMLInputElement>) => {
    stepOne.fullName = e.target.value;
  };

  const handleChangeSpecialties = (e: ChangeEvent<HTMLSelectElement>) => {
    getSpecialtyById(e.target.value);
    stepOne.professionalType = e.target.value;
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    stepOne.email = e.target.value;
  };

  const handleSubmit = (e: IFormData) => {
    if (e.isValid) {
      setShowValidation(false);
      setStepper('two');
      scrollToTop();
    }
    setShowValidation(true);
  };

  return (
    <>
      <HeadingSection title="Crie sua conta">
        <Flex as="a" href={url.loginUrl} justifyContent="flex-start">
          <Text color="primary.600" fontSize="sm" textDecor="underline" fontWeight="medium">
            Já tenho uma conta
          </Text>
        </Flex>
      </HeadingSection>
      <Button
        w="full"
        leftIcon={<Google />}
        variant="outline"
        mb={8}
        size="sm"
        minH={{ base: '40px', lg: '56px' }}
        onClick={handleGoogleSignupClick}
      >
        Criar com Google
      </Button>
      <Divider label="ou crie com seu e-mail" mb="8" />
      <Form onSubmit={handleSubmit}>
        <Text fontWeight="medium" mb={4}>Selecione o perfil da sua conta</Text>
        <RadioGroup
          display="flex"
          gap={4}
          mb={8}
          name="profileType"
          defaultValue={stepOne.isStudent ? ProfileType.STUDENT : ProfileType.PROFESSIONAL}
          onChange={handleChangeProfileType}
        >
          <RadioButton
            colorScheme="primary"
            size="md"
            value={ProfileType.PROFESSIONAL}
            isRequired
          >
            Profissional de saúde
          </RadioButton>
          <RadioButton
            colorScheme="primary"
            size="md"
            value={ProfileType.STUDENT}
            isRequired
          >
            Estudante
          </RadioButton>
        </RadioGroup>
        <Select
          label="Tipo de profissional"
          placeholder="Selecione"
          name="professionalType"
          value={stepOne.professionalType}
          options={categories}
          onChange={handleChangeSpecialties}
          showValidation={showValidation}
          isLoading={!categories}
          isRequired
          mb={8}
        />
        <InputCpf
          label="CPF"
          name="cpf"
          value={stepOne.cpf}
          placeholder="Somente números"
          onChange={handleChangeCpf}
          mb={8}
          isRequired
          showValidation={showValidation}
        />
        <InputText
          label="Nome completo"
          name="fullName"
          value={stepOne.fullName}
          onChange={handleChangeFullName}
          mb={8}
          isRequired
          showValidation={showValidation}
          externalCustomValidation={Object.values(nameValidation)}
          errors={{
            custom: 'Insira seu nome completo',
          }}
        />
        <InputEmail
          label="Email"
          name="email"
          value={stepOne.email}
          onChange={handleChangeEmail}
          mb={10}
          isRequired
          showValidation={showValidation}
        />
        <Button type="submit" w="full">Continuar</Button>
      </Form>
    </>
  );
};

export default SignupStepOne;
