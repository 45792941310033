import { createIcon } from '@chakra-ui/icons';

export const Google = createIcon({
  displayName: 'Google',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M15.2919 8.36563C14.4493 7.57786 13.3247 7.14869 12.1612 7.16633C10.0321 7.16633 8.22382 8.57432 7.57907 10.4703V10.4703C7.23721 11.4638 7.23721 12.5397 7.57907 13.5332H7.58206C8.2298 15.4262 10.0351 16.8342 12.1642 16.8342C13.2633 16.8342 14.2068 16.5587 14.9381 16.0719V16.07C15.7987 15.5115 16.3865 14.6326 16.5694 13.639H12.1612V10.5585H19.8591C19.955 11.0935 20 11.6402 20 12.184C20 14.6172 19.1129 16.6743 17.5694 18.0676L17.571 18.0688C16.2185 19.2916 14.3623 20 12.1612 20C9.07546 20 6.2536 18.2951 4.86817 15.5938V15.5937C3.71065 13.3333 3.71065 10.6702 4.86818 8.40974H4.86819L4.86817 8.40972C6.2536 5.70542 9.07546 4.00054 12.1612 4.00054C14.1884 3.97702 16.1466 4.72365 17.622 6.08167L15.2919 8.36563Z"
    />
  ),
});
