/* eslint-disable react/jsx-props-no-spreading */
import ContentWrapper from '@components/Template/ContentWrapper';
import ErrorSection from '@components/Template/ErrorSection';
import { Button, Box } from '@memed/epiderme';
import { KcContextBase } from 'keycloakify';
import { ERROR_DATA } from './Error.data';

export function evaluateError(errorContext: KcContextBase.Error): boolean {
  const cookiesErrorKeywords = 'Cookie not found';

  return errorContext.message && errorContext.message.summary.includes(cookiesErrorKeywords);
}

const Error404 = ({ context }: { context: KcContextBase.Error }) => (
  <ContentWrapper>
    <ErrorSection {...ERROR_DATA.ERROR_404}>
      <Box as="a" flex={1} href={context.url.loginRestartFlowUrl}>
        <Button>Voltar</Button>
      </Box>
    </ErrorSection>
  </ContentWrapper>
);

export default Error404;
