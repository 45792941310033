import { Center } from '@chakra-ui/react';
import { ChevronDown } from '@components/Icons/ChevronDown';
import { Close } from '@components/Icons/Close';
import { useFormData } from '@hooks/useFormData';
import { IOptions } from '@interfaces/specialty';
import {
  Box, InputText, Text,
} from '@memed/epiderme';
import { ChangeEvent, useEffect, useState } from 'react';
import { SearchWrapper, Highlight } from '@components/index';
import { UniversityItem } from './UniversityItem';

const UniversitySearch = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState<string>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { formData: { current: { stepTwo } } } = useFormData();
  const { universities } = useFormData();

  const handleChangeSearchTerm = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleClickUniversities = (university: IOptions) => {
    stepTwo.university = university.value;
    setSelectedUniversity(university.label);
    setShowDropdown(false);
    setSearchTerm('');
  };

  const renderDropdownOrClear = () => {
    if (!selectedUniversity) {
      return (
        <Box
          cursor="pointer"
          onClick={() => setShowDropdown(true)}
        >
          <ChevronDown />
        </Box>
      );
    }
    return (
      <Box
        cursor="pointer"
        onClick={() => {
          setSearchTerm('');
          setSelectedUniversity('');
          stepTwo.university = '';
        }}
      >
        <Close />
      </Box>
    );
  };

  const inputValue = selectedUniversity ? `${selectedUniversity}` : searchTerm;

  const hasResults = universities
    ?.filter((university) => university.label.toLowerCase().includes(searchTerm.toLowerCase()));

  const hasUniversities = universities && universities.length > 0;

  useEffect(() => { if (searchTerm) setShowDropdown(true); }, [searchTerm]);

  return (
    <Box mb={8} position="relative">
      <InputText
        iconLeft="search-loupe"
        placeholder="Digite ou selecione uma instituição"
        label="Nome da instituição"
        onChange={handleChangeSearchTerm}
        iconRight={renderDropdownOrClear()}
        value={inputValue}
      />
      <SearchWrapper showDropdown={showDropdown} onClose={setShowDropdown}>
        {hasUniversities && !searchTerm && universities?.map((university) => (
          <UniversityItem key={university.value} handleClick={handleClickUniversities} university={university}>
            {university.label}
          </UniversityItem>
        ))}
        {hasUniversities && searchTerm && hasResults?.map((university) => (
          <UniversityItem key={university.value} handleClick={handleClickUniversities} university={university}>
            <Highlight
              query={searchTerm}
              styles={{
                px: '2', py: '1', rounded: 'full', bg: 'secondary.200',
              }}
            >
              {university.label}
            </Highlight>
          </UniversityItem>
        ))}
        {!hasUniversities && !hasResults && (
          <Center mt={2} py={2}>
            <Text fontSize="sm">Não há resultados para exibir</Text>
          </Center>
        )}
      </SearchWrapper>
    </Box>
  );
};

export default UniversitySearch;
