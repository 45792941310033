/* eslint-disable react/jsx-props-no-spreading */
import { useKeycloackContext } from '@hooks/useKeycloakContext';
import { KeycloakContextProps } from '@services/keycloak/types/context.type';
import { KcContextBase } from 'keycloakify';
import Cookies, { evaluateError as isCookiesNotFoundError } from './Cookies';
import Default, { evaluateError as isServerError } from './Default';
import Warning, { evaluateError as isServerWarning } from './Warning';
import PageExpired, { evaluateError as isLoginPageExpiredError } from './LoginPageExpired';
import Info, { evaluateError as isInfoError } from './Info';
import Error404 from './Error404';

const Error = (props: KeycloakContextProps) => {
  const { kcContext } = props;
  const { context } = useKeycloackContext<KcContextBase.Error>(kcContext);

  return (() => {
    if (isCookiesNotFoundError(context)) {
      return <Cookies context={context} />;
    }

    if (isLoginPageExpiredError(context)) {
      return <PageExpired context={context} />;
    }

    if (isServerWarning(context)) {
      return <Warning context={context} />;
    }

    if (isServerError(context)) {
      return <Default context={context} />;
    }

    if (isInfoError(context)) {
      return <Info context={context} />;
    }

    return <Error404 context={context} />;
  })();
};

export default Error;
