import { getKcContext, KcContextBase } from 'keycloakify';
import { kcContext as devContext } from '../../../mockContext';

export const keycloakContext = (() => {
  const { kcContext } = getKcContext<{
    pageId: 'login-idp-password-link-confirm.ftl' | 'confirm-update-password.ftl';
  }>();

  if (!kcContext) {
    return devContext as KcContextBase;
  }

  return kcContext;
})();

export type KcContext = NonNullable<typeof keycloakContext>;
