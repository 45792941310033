/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import { Google } from '@components/Icons/Google';
import { PatientsCTA } from '@components/index';
import ContentWrapper from '@components/Template/ContentWrapper';
import HeadingSection from '@components/Template/HeadingSection';
import { useKeycloackContext } from '@hooks/useKeycloakContext';
import {
  Button, Flex, Text, Divider,
} from '@memed/epiderme';
import { KeycloakContextProps } from '@services/keycloak/types/context.type';
import { useEffect } from 'react';
import { KcContextBase } from 'keycloakify';
import { useZendesk } from '@hooks/useZendesk';
import { LoginForm } from './LoginForm';

declare const window: Window & { zE: { hide: () => void } };

const Login = (props: KeycloakContextProps) => {
  const { kcContext } = props;
  const { context: { url } } = useKeycloackContext<KcContextBase.Login>(kcContext);
  const { hideZendesk } = useZendesk();

  const handleGoogleLoginClick = () => {
    if (kcContext.social) {
      const googleProvider = kcContext.social.providers.find((provider) => provider.providerId === 'google');
      if (googleProvider) {
        window.location.href = googleProvider.loginUrl;
      }
    }
  };

  useEffect(() => {
    hideZendesk();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentWrapper>
      <HeadingSection title="Entre na memed">
        <Flex as="a" href={url.registrationUrl} justifyContent="flex-start">
          <Text color="primary.600" fontSize="sm" textDecor="underline" fontWeight="medium">
            Ainda não tenho conta
          </Text>
        </Flex>
      </HeadingSection>
      <Button
        w="full"
        leftIcon={<Google />}
        variant="outline"
        mb={8}
        size="sm"
        minH={{ base: '40px', lg: '56px' }}
        onClick={handleGoogleLoginClick}
      >
        Entrar com Google
      </Button>
      <Divider label="ou entre com suas credenciais" mb="8" />
      <LoginForm {...props} />
      <PatientsCTA />
    </ContentWrapper>
  );
};
export default Login;
