/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import { Box, omitThemingProps, useStyleConfig } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import {
  Chunk, HighlightOptions, HighlightProps, MarkProps, UseHighlightProps,
} from './Highlight.interface';

const escapeRegexp = (term: string): string => term.replace(/[|\\{}()[\]^$+*?.-]/g, (char: string) => `\\${char}`);

const buildRegex = (_query: string[]) => {
  const query = _query
    .filter((text) => text.length !== 0)
    .map((text) => escapeRegexp(text.trim()));
  if (!query.length) {
    return null;
  }

  return new RegExp(`(${query.join('|')})`, 'ig');
};

const highlightWords = ({ text, query }: HighlightOptions): Chunk[] => {
  const regex = buildRegex(Array.isArray(query) ? query : [query]);
  if (!regex) {
    return [{ text, match: false }];
  }
  const result = text.split(regex).filter(Boolean);
  return result.map((str) => ({ text: str, match: regex.test(str) }));
};

const useHighlight = (props: UseHighlightProps) => {
  const { text, query } = props;
  return useMemo(() => highlightWords({ text, query }), [text, query]);
};

export const Mark = ((props: MarkProps) => {
  const styles = useStyleConfig('Mark', props);
  const ownProps = omitThemingProps(props);
  return (
    <Box
      {...ownProps}
      as="mark"
      __css={{ bg: 'transparent', whiteSpace: 'nowrap', ...styles }}
    />
  );
});

const Highlight = (props: HighlightProps): JSX.Element => {
  const { children, query, styles } = props;

  if (typeof children !== 'string') {
    throw new Error('The children prop of Highlight must be a string');
  }

  const chunks = useHighlight({ query, text: children });

  return (
    <>
      {chunks.map((chunk, index) => (chunk.match ? (
        <Mark key={index} sx={styles}>
          {chunk.text}
        </Mark>
      ) : (
        <React.Fragment key={index}>{chunk.text}</React.Fragment>
      )))}
    </>
  );
};

export default Highlight;
