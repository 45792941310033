export const birthDateMask = (date: string) => date
  .replace(/\D/g, '')
  .replace(/(\d{2})(\d)/, '$1/$2')
  .replace(/(\d{2})(\d)/, '$1/$2')
  .replace(/(\d{2})(\d{2})$/, '$1$2');

export const isValidBirthDate = (date: string) => {
  const MAXIMUM_DIFFERENCE = 150;
  const MINIMUM_MAXIMUM_DIFFERENCE = 18;
  const dateInput = new Date(date);
  const currentDate = new Date(Date.now());
  if (currentDate.getFullYear() - dateInput.getFullYear() > MAXIMUM_DIFFERENCE) {
    return false;
  }

  if (currentDate.getFullYear() - dateInput.getFullYear() < MINIMUM_MAXIMUM_DIFFERENCE) {
    return false;
  }

  if (dateInput > currentDate) {
    return false;
  }

  if (!dateInput || Number.isNaN(dateInput.getTime())) {
    return false;
  }

  return true;
};
