import { ISpecialtyResponse } from '@interfaces/specialty';
import { AxiosResponse } from 'axios';
import { specialtyInstance } from './axiosInstance';

type IAxiosSpecialtyResponse = AxiosResponse<ISpecialtyResponse[]>;

class SpecialtyRepository {
  private static onlyFirstLetterUpperCase(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  private static specialtyMapper(data: ISpecialtyResponse[]) {
    return data.map((specialty) => ({
      value: String(specialty.id),
      label: this.onlyFirstLetterUpperCase(specialty.description),
    }));
  }

  static get() {
    // Usaremos o mockData para simular o retorno da API
    // enquanto não temos o endpoint de especialidades
    const mockData = [
      {
        description: 'Médico',
        id: 1,
      },
      {
        description: 'Cirurgião dentista',
        id: 2,
      },
    ];
    return this.specialtyMapper(mockData);
  }

  static async getSpecialtyById(id: string) {
    const response: IAxiosSpecialtyResponse = await specialtyInstance.get(`/${id}`);
    return this.specialtyMapper(response.data);
  }
}

export default SpecialtyRepository;
