/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import ContentWrapper from '@components/Template/ContentWrapper';
import { KeycloakContextProps } from '@services/keycloak/types/context.type';
import { useKeycloackContext } from '@hooks/useKeycloakContext';
import { KcContextBase } from 'keycloakify';
import { useFormData } from '@hooks/useFormData';
import { SubmitForm } from '@utils/SubmitForm';
import { useZendesk } from '@hooks/useZendesk';
import { useEffect } from 'react';
import { useToast } from '@memed/epiderme';
import Messages, { ErrorsMessages } from '@services/api/Messages';
import SignupStepOne from './Forms/SignupStepOne/SignupStepOne';
import SignupStepTwo from './Forms/SignupStepTwo/SignupStepTwo';
import { formatSignupData } from './Forms/Signup.data';
import SignupStepOneSocial from './Forms/SignupStepOneSocial/SignupStepOneSocial';
import SignupStepTwoSocial from './Forms/SignupStepTwoSocial/SignupStepTwoSocial';

const Signup = (props: KeycloakContextProps) => {
  const { stepper, formData } = useFormData();
  const { kcContext } = props;
  const { context: { url, social } } = useKeycloackContext<KcContextBase.RegisterUserProfile>(kcContext);
  const { initZendesk } = useZendesk();
  const toast = useToast();

  useEffect(() => {
    initZendesk('Cadastro');
  }, []);

  useEffect(() => {
    if (kcContext.message) {
      toast(Messages.error(kcContext.message.summary, ErrorsMessages.GENERIC_CONTENT));
    }
  }, []);

  const onSubmit = (isSocial = false) => {
    const dataToSubmit = formatSignupData(formData.current, isSocial);
    const form = new SubmitForm({
      action: url.registrationAction,
      method: 'POST',
    });

    form.execute(dataToSubmit);
  };

  switch (stepper) {
    case 'one':
      return (
        <ContentWrapper>
          {
            kcContext.firstBrokerLoginInProgress
              ? <SignupStepOneSocial url={url} prefill={kcContext?.prefill} />
              : <SignupStepOne url={url} social={social} />
          }
        </ContentWrapper>

      );
    case 'two':
      return (
        <ContentWrapper>
          {
            kcContext.firstBrokerLoginInProgress
              ? <SignupStepTwoSocial {...props} onSubmit={() => onSubmit(true)} />
              : <SignupStepTwo {...props} onSubmit={onSubmit} />
          }
        </ContentWrapper>

      );
    default:
      return (
        <ContentWrapper>
          {
            kcContext.firstBrokerLoginInProgress
              ? <SignupStepOneSocial url={url} prefill={kcContext?.prefill} />
              : <SignupStepOne url={url} social={social} />
          }
        </ContentWrapper>
      );
  }
};

export default Signup;
