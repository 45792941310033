import { AppStore } from '@components/Icons/AppStore';
import { GooglePlay } from '@components/Icons/GooglePlay';
import { Flex, Link, Text } from '@memed/epiderme';
import { patientsAppLinks } from './PatientsCTA.data';

const PatientsCTA = () => (
  <>
    <Text textAlign="center" width="220px" marginX="auto" lineHeight="base" mb={3}>
      {
        `Tem uma prescrição Memed?
        Baixe o app de paciente`
      }
    </Text>
    <Flex gap={2} justifyContent="center">
      <Link href={patientsAppLinks.googlePlay} target="_blank">
        <GooglePlay color="neutral.600" w={6} h={6} transition="0.2s" _hover={{ color: 'primary.700' }} />
      </Link>
      <Link href={patientsAppLinks.appStore} target="_blank">
        <AppStore color="neutral.600" w={6} h={6} transition="0.2s" _hover={{ color: 'primary.700' }} />
      </Link>
    </Flex>
  </>
);

export default PatientsCTA;
