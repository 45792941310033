import { useFormData } from '@hooks/useFormData';
import { ProfileType } from '@interfaces/user';
import {
  Button, Form, IFormData, InputCpf, InputEmail, InputText,
  RadioButton, RadioGroup, Select, Text, Flex, Divider, InputBase,
} from '@memed/epiderme';
import HeadingSection from '@components/Template/HeadingSection';
import { scrollToTop } from '@utils/scroll';
import { ChangeEvent, useEffect, useState } from 'react';
import { nameValidation } from '@utils/validations/name';
import { IUrl } from '../SignupStepOne/SignupStepOne';

export interface IPrefill {
  prefill?: {
    email?: string;
    firstName?: string;
    lastName?: string;
  }
}

type ISignupStepOne = IUrl & IPrefill;

const SignupStepOneSocial = ({ url, prefill }: ISignupStepOne) => {
  const [showValidation, setShowValidation] = useState(false);
  const { formData: { current: { stepOne } } } = useFormData();
  const {
    categories, getSpecialtyById, getUniversities, setStepper,
  } = useFormData();

  useEffect(() => {
    if (prefill) {
      stepOne.email = prefill.email || '';
      stepOne.fullName = `${prefill.firstName || ''} ${prefill.lastName || ''}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeProfileType = (value: string) => {
    stepOne.isStudent = value === ProfileType.STUDENT;
    if (stepOne.isStudent) getUniversities();
  };

  const handleChangeCpf = (e: ChangeEvent<HTMLInputElement>) => {
    stepOne.cpf = e.target.value;
  };

  const handleChangeFullName = (e: ChangeEvent<HTMLInputElement>) => {
    stepOne.fullName = e.target.value;
  };

  const handleChangeSpecialties = (e: ChangeEvent<HTMLSelectElement>) => {
    getSpecialtyById(e.target.value);
    stepOne.professionalType = e.target.value;
  };

  // const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
  //   stepOne.email = e.target.value;
  // };

  const handleSubmit = (e: IFormData) => {
    if (e.isValid) {
      setShowValidation(false);
      setStepper('two');
      scrollToTop();
    }
    setShowValidation(true);
  };

  const handleClickBack = () => { window.location.href = url.loginRestartFlowUrl; };

  return (
    <>
      <HeadingSection title="Entre na memed">
        <Flex as="a" href={url.loginUrl} justifyContent="flex-start">
          <Text color="primary.600" fontSize="sm" textDecor="underline" fontWeight="medium">
            Já tenho uma conta
          </Text>
        </Flex>
      </HeadingSection>
      <Divider label="cadastro com Google" mb="8" />
      <Form onSubmit={handleSubmit}>
        <Text fontWeight="medium" mb={4}>Selecione o perfil da sua conta</Text>
        <RadioGroup
          display="flex"
          gap={4}
          mb={8}
          name="profileType"
          defaultValue={stepOne.isStudent ? ProfileType.STUDENT : ProfileType.PROFESSIONAL}
          onChange={handleChangeProfileType}
        >
          <RadioButton
            colorScheme="primary"
            size="md"
            value={ProfileType.PROFESSIONAL}
            isRequired
          >
            Profissional de saúde
          </RadioButton>
          <RadioButton
            colorScheme="primary"
            size="md"
            value={ProfileType.STUDENT}
            isRequired
          >
            Estudante
          </RadioButton>
        </RadioGroup>
        <Select
          label="Tipo de profissional"
          placeholder="Selecione"
          name="professionalType"
          value={stepOne.professionalType}
          options={categories}
          onChange={handleChangeSpecialties}
          showValidation={showValidation}
          isLoading={!categories}
          isRequired
          mb={8}
        />
        <InputCpf
          label="CPF"
          name="cpf"
          value={stepOne.cpf}
          placeholder="Somente números"
          onChange={handleChangeCpf}
          mb={8}
          isRequired
          showValidation={showValidation}
        />
        <InputBase
          label="Nome completo"
          name="fullName"
          value={stepOne.fullName || `${prefill?.firstName || ''} ${prefill?.lastName || ''}`}
          onChange={handleChangeFullName}
          mb={8}
          isRequired
          showValidation={showValidation}
          externalCustomValidation={Object.values(nameValidation)}
          errors={{
            custom: ['Insira seu nome completo'],
          }}
        />
        <InputEmail
          label="Email"
          name="email"
          value={stepOne.email || prefill?.email}
          // onChange={handleChangeEmail}
          isDisabled
          mb={10}
          isRequired
          showValidation={showValidation}
        />
        <Flex gap={6} justifyContent="flex-end">
          <Button onClick={handleClickBack} variant="ghost">Voltar</Button>
          <Button w="full" maxW="50%" type="submit" minW="190px">Continuar</Button>
        </Flex>
      </Form>
    </>
  );
};

export default SignupStepOneSocial;
