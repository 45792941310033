import { createIcon } from '@chakra-ui/icons';

export const LogoMobile = createIcon({
  displayName: 'LogoMobile',
  viewBox: '0 0 120 120',
  path: (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="120" height="120" rx="60" fill="#6161FF" />
      <path d="M60.0002 51.9055C65.3071 44.492 72.9365 39.639 81.9872 39.639C92.9438 39.639 100 46.07 100 57.2199C100 66.0402 97.0896 74.1608 93.7252 80.361H83.9898L83.9152 80.1973C88.2322 72.9624 91.1426 64.2761 91.1426 57.3316C91.1426 50.6922 87.5402 46.9706 82.0617 46.9706C74.3357 46.9706 66.8925 53.5951 60.1416 67.4172H59.8512C53.0854 53.5951 45.7014 46.9706 37.9383 46.9706C32.4602 46.9706 28.8574 50.6773 28.8574 57.3316C28.8574 64.2761 31.7678 72.9922 36.0848 80.1973L36.0103 80.361H26.2748C22.9104 74.1608 20 66.0402 20 57.2199C20 46.07 27.034 39.639 38.0128 39.639C47.0635 39.639 54.6929 44.492 60.0002 51.9055Z" fill="#F7F4F1" />
    </svg>
  ),
});
