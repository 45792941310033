/* eslint-disable import/no-extraneous-dependencies */
import ReactDOM from 'react-dom/client';
import { initializeTracker } from '@services/tracking/x-ray-tracker';
import App from './App';
import '@memed/epiderme/lib/assets/reset.css';
import '@memed/epiderme/lib/assets/fonts/fontFace.css';

initializeTracker();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
