import ContentWrapper from '@components/Template/ContentWrapper';
import HeadingSection from '@components/Template/HeadingSection';
import { useKeycloackContext } from '@hooks/useKeycloakContext';
import {
  Button, Form, IFormData, Text, Link, InputPassword,
} from '@memed/epiderme';
import { KeycloakContextProps } from '@services/keycloak/types/context.type';
import { useEffect, useState } from 'react';
import { KcContextBase } from 'keycloakify';
import { useZendesk } from '@hooks/useZendesk';
import { SubmitForm } from '@utils/SubmitForm';

const LinkToGoogle = (props: KeycloakContextProps) => {
  const { kcContext } = props;
  const { context: { url } } = useKeycloackContext<KcContextBase.Login>(kcContext);
  const { initZendesk } = useZendesk();
  const [showValidation, setShowValidation] = useState(false);
  const [isloading, setIsLoading] = useState(false);

  useEffect(() => {
    initZendesk('Senha');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = ({ isValid, data }: IFormData) => {
    setIsLoading(true);
    if (!isValid) {
      setIsLoading(false);
      setShowValidation(true);
      return;
    }
    setShowValidation(false);
    const dataToSubmit = {
      password: data.password as string,
    };

    const form = new SubmitForm({
      action: url.loginAction,
      method: 'POST',
    });

    form.execute(dataToSubmit);
  };

  return (
    <ContentWrapper>
      <Form onSubmit={onSubmit}>
        <HeadingSection title="Entrar com Google">
          <Text color="neutral.700" fontSize="sm" fontWeight="medium">
            Vincule sua conta Google com a Memed
          </Text>
        </HeadingSection>
        <Text color="neutral.600" fontSize="md" mb={8} lineHeight="150%">
          Para entrar utilizando o Google,
          confirme a senha da sua conta Memed para
          vincular sua conta Google ao seu cadastro.
        </Text>
        <InputPassword name="password" isRequired label="Senha" showValidation={showValidation} mb={4} />
        <Link
          href={url.loginResetCredentialsUrl}
          color="neutral.600"
          fontSize="md"
          textDecor="underline"
          fontWeight="medium"

        >
          Esqueci minha senha
        </Link>
        <Button isLoading={isloading} type="submit" w="full" mt={8}>Continuar</Button>
      </Form>
    </ContentWrapper>
  );
};

export default LinkToGoogle;
