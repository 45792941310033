import { IUniversity } from '@interfaces/user';
import { AxiosResponse } from 'axios';
import { universityInstance } from './axiosInstance';

type IAxiosUniversityResponse = AxiosResponse<IUniversity[]>;

class UniversityRepository {
  private static onlyFirstLetterUpperCase(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  private static universityMapper(data: IUniversity[]) {
    return data.map((university) => ({
      value: university.id,
      label: this.onlyFirstLetterUpperCase(university.name),
    }));
  }

  static async get() {
    const response: IAxiosUniversityResponse = await universityInstance.get('/');
    return this.universityMapper(response.data);
  }
}

export default UniversityRepository;
