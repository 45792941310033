import { InputLoginHealthcare, PatientsCTA } from '@components/index';
import ContentWrapper from '@components/Template/ContentWrapper';
import HeadingSection from '@components/Template/HeadingSection';
import { useKeycloackContext } from '@hooks/useKeycloakContext';
import { useZendesk } from '@hooks/useZendesk';
import {
  Button, Form, IFormData, Text,
} from '@memed/epiderme';
import { KeycloakContextProps } from '@services/keycloak/types/context.type';
import { trackForgotPasswordPageview } from '@services/tracking/x-ray-tracker';
import { KcContextBase } from 'keycloakify';
import { useEffect, useRef, useState } from 'react';
import MessageSent from './MessageSent';

const ForgotPassword = (props: KeycloakContextProps) => {
  const { kcContext } = props;
  const { context: { url } } = useKeycloackContext<KcContextBase.RegisterUserProfile>(kcContext);
  const username = useRef('');
  const [showValidation, setShowValidation] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { initZendesk } = useZendesk();

  useEffect(() => {
    initZendesk('Perdi minha senha');
    trackForgotPasswordPageview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    username.current = e.target.value;
  };

  const onSubmit = ({ isValid, data }: IFormData) => {
    if (!isValid) {
      setShowValidation(true);
      return;
    }
    setShowValidation(false);

    fetch(url.loginAction, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      method: 'POST',
      body: `username=${data.username as string}`,
    });
    setHasSubmitted(true);
  };

  useEffect(() => () => setHasSubmitted(false), []);

  const onBackClick = () => setHasSubmitted(false);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return hasSubmitted ? <MessageSent {...props} onBackClick={onBackClick} /> : (
    <ContentWrapper>
      <Form onSubmit={onSubmit}>
        <HeadingSection title="Redefinição de senha">
          <Text color="neutral.700" fontSize="sm" fontWeight="medium">
            Digite seu e-mail ou CPF
          </Text>
        </HeadingSection>
        <Text color="neutral.600" mb={8} lineHeight="base">
          Para redefinir sua senha, digite seu CPF ou o endereço de e-mail que você pode ter usado com a Memed.
        </Text>
        <InputLoginHealthcare
          label="Email ou CPF"
          mb={8}
          name="username"
          onChange={handleChange}
          showValidation={showValidation}
          isRequired
        />
        <Button type="submit" mb={8} w="full">Continuar</Button>
        <PatientsCTA />
      </Form>
    </ContentWrapper>
  );
};

export default ForgotPassword;
