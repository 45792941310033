type IToastConfig = {
  title?: string,
  description?: string,
  status: 'error' | 'success',
  position: 'bottom-left',
  isClosable: boolean,
};

export enum ErrorsMessages {
  GENERIC_CONTENT = 'Revise seus dados e tente novamente, se você está com problemas para entrar tente redefinir a senha ou entre em contato com nosso suporte e informe o número do erro',
  CPF_LOGIN = 'Insira um CPF válido',
  EMAIL_LOGIN = 'Insira um e-mail válido',
}

class Messages {
  public static error(
    title = 'Alguma coisa deu errado!',
    description = 'Por favor, entre em contato com o suporte!',
  ): IToastConfig {
    return {
      title,
      description,
      status: 'error',
      position: 'bottom-left',
      isClosable: true,
    };
  }

  public static createSuccess(name: string): IToastConfig {
    return {
      title: 'Novo registro criado com sucesso',
      description: `Você já pode começar a prescrever para ${name}!`,
      status: 'success',
      position: 'bottom-left',
      isClosable: true,
    };
  }

  public static editSuccess(name: string): IToastConfig {
    return {
      title: 'Paciente atualizado com sucesso',
      description: `Você já pode começar a prescrever para ${name}!`,
      status: 'success',
      position: 'bottom-left',
      isClosable: true,
    };
  }

  public static discardedChanges(): IToastConfig {
    return {
      title: 'As alterações foram descartadas',
      status: 'success',
      position: 'bottom-left',
      isClosable: true,
    };
  }
}

export default Messages;
