import type {
  Application, DigitalPrescriptionProperties, PlatformProperties,
} from '@memed/x-ray-tracker-js';

let environment = 'development';
const firstDomain = document.location.host.replace('www.', '').split('.')[0];

if (firstDomain === 'memed') {
  environment = 'production';
} else {
  environment = firstDomain;
}

const xRayTracker = (() => {
  try {
    return new window.XRayTracker(environment);
  } catch {
    return undefined;
  }
})();

export const initializeTracker = (): boolean => {
  if (!xRayTracker) {
    return false;
  }

  try {
    const app: Application = {
      version: process.env.REACT_APP_VERSION || 'unknown',
      name: 'Módulo de Usuário v3',
      build: '0.0.0',
      namespace: 'module.user',
    };

    xRayTracker.setMode(firstDomain.includes('local') ? 'DEBUG' : 'LIVE');
    xRayTracker.load(app);
    return true;
  } catch {
    return false;
  }
};

const handleError = (error: unknown) => {
  const message = (error instanceof Error && error.message) || 'desconhecido';

  // eslint-disable-next-line no-console
  console.error(new Error(`[XRay] Não foi possível enviar evento\n Motivo: ${message}`));
};

const trackEvent = (
  eventName: string,
  pageName: string,
  properties?: DigitalPrescriptionProperties | PlatformProperties,
): boolean => {
  if (!xRayTracker) {
    return false;
  }

  try {
    return xRayTracker.event(eventName, pageName, properties);
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const trackForgotMyPasswordClick = (): void => {
  trackEvent('Forgot My Password Click', 'login');
};

export const trackForgotPasswordPageview = (): void => {
  trackEvent('Forgot Password Pageview', 'forgot_password');
};

export const trackForgotPasswordEmailSentPageview = (): void => {
  trackEvent('Forgot Password Email Sent Pageview', 'forgot_password_email_sent');
};

export const trackPasswordResetPageview = (): void => {
  trackEvent('Password Reset Pageview', 'assword_reset_new_password');
};

export const trackPasswordResetSuccessfullyPageview = (): void => {
  trackEvent('Password Reset Successfully Pageview', 'password_reset_successfully');
};
