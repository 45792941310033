/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
import {
  Input as ChakraInput, InputGroup, InputLeftElement, InputRightElement,
} from '@chakra-ui/react';
import { useInputValidation, Icon, InputBase } from '@memed/epiderme';
import { useEffect } from 'react';
import { IInputBase } from '@memed/epiderme/lib/components/Forms/Inputs/InputBase/InputBase.interface';
import { isValidLoginHealthcare } from '@utils/validations/isValidLoginHealthcare';
import { maskLoginHealthcare } from '@utils/masks/maskLoginHealthcare';

const InputLoginHealthcare = ({ ...props }: IInputBase) => {
  const LOGIN_HEALHCARE_CUSTOM_ERROR_MESSAGE = 'Insira um CPF ou email válido';

  const {
    isValid,
    errorMessage,
    showValidation,
    setShowValidation,
    handleOnChange,
    handleOnBlur,
    iconColor,
    fieldValue,
    updateAndValidate,
    isFilled,
  } = useInputValidation({
    props,
    customValidation: [isValidLoginHealthcare],
    customErrorMessage: [LOGIN_HEALHCARE_CUSTOM_ERROR_MESSAGE],
    mask: maskLoginHealthcare,
  });

  useEffect(() => {
    if (props.showValidation !== showValidation) {
      setShowValidation(!!props.showValidation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showValidation]);

  useEffect(() => {
    if (props.value !== undefined && props.value !== null && props.value !== fieldValue) {
      updateAndValidate(props.value as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <InputBase
      isInvalid={showValidation && !isValid}
      errorText={errorMessage}
      {...props}
    >
      <InputGroup>
        {props.iconLeft && (typeof props.iconLeft === 'string'
          ? <InputLeftElement children={<Icon icon={props.iconLeft} color={iconColor()} />} />
          : <InputLeftElement children={props.iconLeft} />)}
        <ChakraInput
          className={props.isSuccess ? 'input-success' : ''}
          value={fieldValue}
          onChange={(evt) => handleOnChange(evt)}
          onBlur={(evt) => handleOnBlur(evt)}
          name={props.name}
          placeholder={props.placeholder}
          type="text"
          data-testid="input-date"
          data-is-valid={isValid}
          data-is-filled={isFilled}
          data-error-message={errorMessage}
          data-set-show-validation={JSON.stringify(setShowValidation)}
          inputMode="email"
        />
        {props.iconRight && (typeof props.iconRight === 'string'
          ? <InputRightElement children={<Icon icon={props.iconRight} color={iconColor()} />} />
          : <InputRightElement children={props.iconRight} />)}
      </InputGroup>
    </InputBase>
  );
};

export default InputLoginHealthcare;
