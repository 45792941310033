import { InputBirthDate, SpecialtySearch, UniversitySearch } from '@components/index';
import InputCellPhone from '@components/InputCellPhone/InputCellPhone';
import { useFormData } from '@hooks/useFormData';
import { ProfessionalTitle } from '@interfaces/user';
import {
  Button, Checkbox, Flex, Form, IFormData,
  InputText, Link, RadioButton,
  RadioGroup, Select, Text, TooltipHelper,
} from '@memed/epiderme';
import { ChangeEvent, useState } from 'react';
import { BR_STATES } from '@utils/brStates';
import { LINKS } from '../SignupStepTwo/SignupStepTwo.data';

export interface ISignupstepTwo {
  onSubmit: () => void;
}

const SignupStepTwo = ({ onSubmit }: ISignupstepTwo) => {
  const [showValidation, setShowValidation] = useState(false);
  const { formData, categories, setStepper } = useFormData();
  const { stepOne, stepTwo } = formData.current;

  const handleChangeProfileTitle = (value: string) => {
    stepTwo.title = value as ProfessionalTitle;
  };

  const handleChangeProfessionalRegistry = (e: ChangeEvent<HTMLInputElement>) => {
    stepTwo.professionalRegistry = e.target.value;
  };

  const handleChangeProfessionalRegistryUF = (e: ChangeEvent<HTMLSelectElement>) => {
    stepTwo.professionalRegistryUF = e.target.value;
  };

  const handleChangeBirthDate = (e: ChangeEvent<HTMLInputElement>) => {
    stepTwo.birthDate = e.target.value;
  };

  const handleChangeCellPhone = (e: ChangeEvent<HTMLInputElement>) => {
    stepTwo.cellPhone = e.target.value;
  };

  const handleChangeTermsOfUse = (e: ChangeEvent<HTMLInputElement>) => {
    stepTwo.agreeToTermsOfUse = e.target.checked;
  };

  const handleClickBack = () => setStepper('one');

  const handleSubmit = (e: IFormData) => {
    if (e.isValid) {
      onSubmit();
      setShowValidation(false);
    }
    setShowValidation(true);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Text fontWeight="medium" mb={4}>Qual sua preferência de tratamento?</Text>
      <RadioGroup
        display="flex"
        gap={4}
        mb={8}
        defaultValue={stepTwo.title}
        onChange={handleChangeProfileTitle}
      >
        <RadioButton
          colorScheme="primary"
          name="profileTitle"
          size="md"
          value={ProfessionalTitle.DR}
          isRequired
        >
          <Text as="label" color="neutral.600">Dr.</Text>
        </RadioButton>
        <RadioButton
          colorScheme="primary"
          name="profileTitle"
          size="md"
          value={ProfessionalTitle.DRA}
          isRequired
        >
          <Text as="label" color="neutral.600">Dra.</Text>
        </RadioButton>
      </RadioGroup>
      <Flex gap={2} mb={8} display={stepOne.isStudent ? 'none' : 'flex'}>
        <InputText
          minW="160px"
          label={categories?.find((cat) => cat.value === stepOne.professionalType)?.label === 'Médico' ? 'CRM' : 'CRO'}
          type="number"
          placeholder="Apenas números"
          name="professionalRegistry"
          value={stepTwo.professionalRegistry}
          showValidation={showValidation}
          onChange={handleChangeProfessionalRegistry}
          isRequired={!stepOne.isStudent}
        />
        <Select
          name="professionalRegistryUF"
          label="UF do registro"
          placeholder="Selecione"
          value={stepTwo.professionalRegistryUF}
          onChange={handleChangeProfessionalRegistryUF}
          options={BR_STATES}
          isRequired={!stepOne.isStudent}
        />
      </Flex>
      {!stepOne.isStudent && <SpecialtySearch showValidation={showValidation} />}
      {stepOne.isStudent && <UniversitySearch />}
      <InputBirthDate
        label="Data de nascimento"
        name="birthDate"
        mb={8}
        value={stepTwo.birthDate}
        showValidation={showValidation}
        onChange={handleChangeBirthDate}
        isRequired
      />
      <InputCellPhone
        label="Celular com DDD"
        name="cellPhone"
        placeholder="Somente números"
        mb={8}
        value={stepTwo.cellPhone}
        showValidation={showValidation}
        onChange={handleChangeCellPhone}
        iconRight={(
          <TooltipHelper
            placement="right"
            labelContent="Este número será utilizado para confirmar a sua conta e não será
            compartilhado com seus pacientes a não ser que você deseje compartilhá-lo."
          />
        )}
        isRequired
      />
      <Checkbox
        color="neutral.600"
        name="agreeToTermsOfUse"
        mb={8}
        defaultChecked={stepTwo.agreeToTermsOfUse}
        onChange={handleChangeTermsOfUse}
        isRequired
      >
        Estou de acordo com os
        {' '}
        <Link href={LINKS.termsOfUse} target="_blank" rel="noreferrer" color="neutral.600">
          Termos de Uso
        </Link>
        {' '}
        e
        {' '}
        <Link href={LINKS.privacyPolicy} target="_blank" rel="noreferrer" color="neutral.600">
          Política de Privacidade
        </Link>
      </Checkbox>
      <Flex gap={6}>
        <Button onClick={handleClickBack} w="full" variant="ghost">Voltar</Button>
        <Button type="submit" minW="190px">Criar conta</Button>
      </Flex>
    </Form>
  );
};

export default SignupStepTwo;
