import { ChakraProvider } from '@chakra-ui/react';
import theme from '@memed/epiderme';
import { FormDataProvider } from '@hooks/useFormData';
import { AppRoutes } from './routes';

const App = () => (
  <ChakraProvider theme={theme}>
    <FormDataProvider>
      <AppRoutes />
    </FormDataProvider>
  </ChakraProvider>
);

export default App;
