/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
type SubmitFormConstructorProps = {
  action: string,
  method: 'POST' | 'GET'
};

export class SubmitForm {
  private _form: HTMLFormElement;

  constructor({ action, method }: SubmitFormConstructorProps) {
    this._form = document.createElement('form');
    this._form.action = action;
    this._form.method = method;
    this._form.style.display = 'none';
  }

  public execute(params : { [key: string]: string }) {
    for (const [key, value] of Object.entries(params)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = value;
      this._form?.appendChild(hiddenField);
    }

    document.body.appendChild(this._form);
    this._form?.submit();
    document.body.removeChild(this._form);
  }
}
