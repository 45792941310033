/* eslint-disable react-hooks/exhaustive-deps */
import { InputLoginHealthcare } from '@components/index';
import {
  Box, Flex, InputPassword, Button, Form, IFormData, Link,
} from '@memed/epiderme';
import { KeycloakContextProps } from '@services/keycloak/types/context.type';
import { SubmitForm } from '@utils/SubmitForm';
import { KcContextBase } from 'keycloakify';
import { useState, useEffect } from 'react';
import { isValidCPF } from '@utils/validations/isValidCPF';
import { onlyNumbers } from '@utils/onlyNumbers';
import { trackForgotMyPasswordClick } from '@services/tracking/x-ray-tracker';
import { seemsLikeCpf } from '@utils/seemsLikeCpf';
import { ErrorsMessages } from '@services/api/Messages';
import { isValidEmail } from '@utils/validations/isValidEmail';
import { useKeycloackContext } from '../../hooks/useKeycloakContext';

export const LoginForm = ({ kcContext }: KeycloakContextProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [customError, setCustomError] = useState('');
  const { context: { url } } = useKeycloackContext<KcContextBase.Login>(kcContext);
  useEffect(() => {
    if (kcContext.message) {
      setShowValidation(true);
    }
  }, []);

  const onSubmit = ({ data }: IFormData) => {
    setIsLoading(true);
    const dataToSubmit = {
      username: isValidCPF(data.emailOrCpf as string)
        ? onlyNumbers(data.emailOrCpf as string)
        : data.emailOrCpf as string,
      password: data.password as string,
    };

    const form = new SubmitForm({
      action: url.loginAction,
      method: 'POST',
    });

    form.execute(dataToSubmit);
  };

  /**
   * Verifica se tem mensagem de erro para exibir, priorizando qual será exibida
   * @returns Mensagem de erro pro campo de Input do login
   */
  const getInputLoginHealthcareErrorMessage = () => {
    if (customError) {
      return customError;
    }

    return kcContext.message ? kcContext.message.summary : undefined;
  };

  /**
   * Seta estado com mensagem de erro
   * @param e Evento de focusOut do input
   * @returns Void
   */
  const verifyInputContent = (e: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (seemsLikeCpf(inputValue)) {
      if (!isValidCPF(inputValue)) {
        setCustomError(ErrorsMessages.CPF_LOGIN);
      }
    } else if (!isValidEmail(inputValue)) {
      setCustomError(ErrorsMessages.EMAIL_LOGIN);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <Flex flexDir="column" gap={10}>
        <InputLoginHealthcare
          label="Email ou CPF"
          name="emailOrCpf"
          showValidation={!!customError || showValidation}
          isInvalid={!!customError || showValidation}
          errorText={getInputLoginHealthcareErrorMessage()}
          onBlur={verifyInputContent}
          onFocus={() => setCustomError('')}
        />
        <Box>
          <InputPassword
            label="Senha"
            name="password"
            mb={4}
            showValidation={showValidation}
            isInvalid={showValidation}
            errorText={kcContext.message ? kcContext.message.summary : undefined}
          />
          <Link
            href={url.loginResetCredentialsUrl}
            color="neutral.600"
            fontSize="md"
            textDecor="underline"
            fontWeight="medium"
            onClick={trackForgotMyPasswordClick}
          >
            Esqueci minha senha
          </Link>
        </Box>
        <Button isLoading={isLoading} type="submit" mb={8}>Continuar</Button>
      </Flex>
    </Form>
  );
};
