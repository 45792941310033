import ContentWrapper from '@components/Template/ContentWrapper';
import HeadingSection from '@components/Template/HeadingSection';
import { useKeycloackContext } from '@hooks/useKeycloakContext';
import { useZendesk } from '@hooks/useZendesk';
import {
  Button, Text, Tag, Flex, Box,
} from '@memed/epiderme';
import { KeycloakContextProps } from '@services/keycloak/types/context.type';
import { trackForgotPasswordEmailSentPageview } from '@services/tracking/x-ray-tracker';
import { KcContextBase } from 'keycloakify';
import { useEffect } from 'react';
import { Back } from '../../components/Icons/Back';

interface IMessageSent extends KeycloakContextProps {
  onBackClick: () => void;
}

const MessageSent = (props: IMessageSent) => {
  const { kcContext, onBackClick } = props;
  const { context: { url } } = useKeycloackContext<KcContextBase.RegisterUserProfile>(kcContext);
  const { initZendesk } = useZendesk();

  useEffect(() => {
    initZendesk('Favoritos');
    trackForgotPasswordEmailSentPageview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentWrapper>
      <HeadingSection title="Redefinição de senha">
        <Tag color="success" iconLeft="info" w="fit-content" size="sm">E-mail enviado</Tag>
      </HeadingSection>
      <Text color="neutral.600" mb={8} lineHeight="base">
        Se estas informações estiverem vinculadas à alguma conta,
        enviaremos um e-mail nos próximos minutos. Caso não receba,
        entre em contato com nosso suporte.
      </Text>
      <Flex gap={4} justifyContent="center">
        <Button leftIcon={<Back />} onClick={onBackClick}>Voltar</Button>
        <Box as="a" flex={1} href={url.loginRestartFlowUrl}>
          <Button mb={8} variant="outline" w="full">Ir para tela inicial</Button>
        </Box>
      </Flex>
    </ContentWrapper>
  );
};

export default MessageSent;
