import { ProfessionalTitle } from '@interfaces/user';

export const DATA = {
  stepOne: {
    professionalType: '',
    cpf: '',
    fullName: '',
    email: '',
    isStudent: false,
  },
  stepTwo: {
    title: '' as ProfessionalTitle,
    professionalRegistry: '',
    professionalRegistryUF: '',
    specialty: '',
    birthDate: '',
    cellPhone: '',
    password: '',
    agreeToTermsOfUse: false,
  },
};

export const SPECIALTIES_MOCK = [
  {
    value: '67ba5cd3-efee-43f9-af77-1f73af61992e',
    label: 'Médico',
  },
  {
    value: '72588c36-f245-4ec9-967a-bb640e5f7605',
    label: 'Cirurgião dentista',
  },
];
