import { Flex, Heading, Box } from '@memed/epiderme';
import { LogoMobile } from '@components/Icons/LogoMobile';
import { ReactNode } from 'react';

interface IHeadingSection {
  children: ReactNode
  title: string
}

const HeadingSection = ({ children, title }: IHeadingSection) => {
  const handleClickLogo = () => {
    window.location.href = 'https://memed.com.br/';
  };
  return (
    <Flex mb={8} gap={4}>
      <Box role="button" onClick={handleClickLogo} h="64px" w="64px">
        <LogoMobile w="full" h="full" />
      </Box>
      <Flex flexDir="column" justifyContent="center" gap={1}>
        <Heading type="title-sm" fontWeight="medium" fontSize={{ base: '22px', lg: '24px' }}>{title}</Heading>
        {children}
      </Flex>
    </Flex>
  );
};

export default HeadingSection;
