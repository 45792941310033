/* eslint-disable react/jsx-props-no-spreading */
import Template from '@components/Template/Template';
import { keycloakContext } from '@services/keycloak/context/keyCloackContext';
import { IKcContext, KeycloakContextProps } from '@services/keycloak/types/context.type';
import { defaultKcProps } from 'keycloakify';
import SuccessfulChangePassword from '@pages/ForgotPassword/SuccessfulChangePassword';
import {
  Signup,
  Login,
  ForgotPassword,
  NewPassword,
  LinkToGoogle,
  Error,
} from '../pages';

const AllRoutes = () => {
  const context: KeycloakContextProps = { kcContext: keycloakContext as IKcContext, ...defaultKcProps };

  switch (keycloakContext.pageId) {
    case 'login.ftl':
      return (<Template hasSignUpCta {...context}><Login {...context} /></Template>);
    case 'register-user-profile.ftl':
    case 'register.ftl':
      return (<Template><Signup {...context} /></Template>);
    case 'login-reset-password.ftl':
      return (<Template><ForgotPassword {...context} /></Template>);
    case 'login-update-password.ftl':
      return (<Template><NewPassword {...context} /></Template>);
    case 'confirm-update-password.ftl':
      return (<Template><SuccessfulChangePassword {...context} /></Template>);
    case 'login-idp-password-link-confirm.ftl':
      return (<Template><LinkToGoogle {...context} /></Template>);
    // case 'practitioner_blocked.ftl':
    //   return (<Template><UserBlocked {...context} /></Template>);
    default:
      return <Error {...context} />;
  }
};

export default AllRoutes;
