import { createIcon } from '@chakra-ui/icons';

export const Back = createIcon({
  displayName: 'Back',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M10.5303 6.46967C10.8232 6.76256 10.8232 7.23744 10.5303 7.53033L6.81066 11.25H19C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75H6.81066L10.5303 16.4697C10.8232 16.7626 10.8232 17.2374 10.5303 17.5303C10.2374 17.8232 9.76256 17.8232 9.46967 17.5303L4.46967 12.5303C4.17678 12.2374 4.17678 11.7626 4.46967 11.4697L9.46967 6.46967C9.76256 6.17678 10.2374 6.17678 10.5303 6.46967Z"
    />
  ),
});
