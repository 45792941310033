/* eslint-disable react/jsx-props-no-spreading */
import ContentWrapper from '@components/Template/ContentWrapper';
import ErrorSection from '@components/Template/ErrorSection';
import { Box, Button } from '@memed/epiderme';
import { KcContextBase } from 'keycloakify';
import { ERROR_DATA } from './Error.data';

export function evaluateError(errorContext: KcContextBase): boolean {
  return errorContext.pageId === 'info.ftl';
}

const LoginPageExpired = ({ context }: { context: KcContextBase.Error }) => (
  <ContentWrapper>
    <ErrorSection {...ERROR_DATA.ERROR_INFO}>
      <Box as="a" flex={1} href={context.url.loginRestartFlowUrl}>
        <Button>Voltar</Button>
      </Box>
    </ErrorSection>
  </ContentWrapper>
);

export default LoginPageExpired;
