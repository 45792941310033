import { Box } from '@memed/epiderme';
import {
  Dispatch, ReactNode, SetStateAction, useEffect, useRef,
} from 'react';

interface ISearchWrapper {
  children: ReactNode,
  showDropdown: boolean,
  onClose: Dispatch<SetStateAction<boolean>>
  noLabel?: boolean
}

const SearchWrapper = ({
  children, showDropdown, onClose, noLabel,
}: ISearchWrapper) => {
  const resultsWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (resultsWrapperRef.current && !resultsWrapperRef.current.contains(event.target as Node)) {
        onClose(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsWrapperRef]);

  return (
    <Box
      position="absolute"
      top={noLabel ? '52px' : '74px'}
      left={0}
      width="100%"
      maxH="300px"
      background="white"
      overflow="auto"
      paddingBottom={2}
      zIndex={5}
      boxShadow="md"
      border="1px"
      borderColor="gray.200"
      borderRadius="lg"
      display={showDropdown ? 'block' : 'none'}
      ref={resultsWrapperRef}
    >
      {children}
    </Box>
  );
};

export default SearchWrapper;
