import { IOptions } from '@interfaces/specialty';
import { Box, Text } from '@memed/epiderme';
import { ReactNode } from 'react';

interface IUniversityItem {
  children: ReactNode
  handleClick: (presentation: IOptions) => void
  university: IOptions
}

export const UniversityItem = ({ children, handleClick, university }: IUniversityItem) => (
  <Box
    as="button"
    paddingY={2}
    paddingX={3}
    cursor="pointer"
    display="flex"
    gap={3}
    marginTop={2}
    width="100%"
    _hover={{
      background: 'primary.50',
    }}
    onClick={() => handleClick(university)}
  >
    <Text lineHeight="base" color="gray.700" fontSize="sm">
      {children}
    </Text>
  </Box>
);
