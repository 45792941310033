import { LogoMobile } from '@components/Icons/LogoMobile';
import {
  Flex, Heading, Box, IconFeatured, Text,
} from '@memed/epiderme';
import { ReactNode } from 'react';

interface IErrorSection {
  children: ReactNode
  title: string
  icon: string
  description?: string
}

const ErrorSection = ({
  children, title, icon, description,
}: IErrorSection) => (
  <Flex mb={8} gap={4}>
    <Flex flexDir="column" justifyContent="center" gap={1}>
      <Flex mb={8} justifyContent="center">
        <IconFeatured icon={icon} />
      </Flex>
      <Heading type="title-sm" fontWeight="medium" fontSize={{ base: '22px', lg: '24px' }} mb={8}>{title}</Heading>
      {description && <Text mb={8}>{description}</Text>}
      {children}
    </Flex>
  </Flex>
);

export default ErrorSection;
