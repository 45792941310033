import { ISpecialty } from '@interfaces/user';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

const SPECIALTY_URL = 'https://qa-gateway.memed.com.br/v2/hipocrates-api/specialities';
const UNIVERSITY_URL = 'https://qa-gateway.memed.com.br/v2/hipocrates-api/universities';

const specialtyInstance: AxiosInstance = axios.create({
  baseURL: SPECIALTY_URL,
  headers: {
    Accept: 'application/json',
  },
});

const universityInstance: AxiosInstance = axios.create({
  baseURL: UNIVERSITY_URL,
  headers: {
    Accept: 'application/json',
  },
});

universityInstance.interceptors.response.use(
  (response: AxiosResponse<ISpecialty>) => response,
  (error: AxiosError) => Promise.reject(error.response?.data),
);

universityInstance.interceptors.request.use(
  (config) => config,
  (error: AxiosError) => Promise.reject(error.response?.data),
);

specialtyInstance.interceptors.response.use(
  (response: AxiosResponse<ISpecialty>) => response,
  (error: AxiosError) => Promise.reject(error.response?.data),
);

specialtyInstance.interceptors.request.use(
  (config) => config,
  (error: AxiosError) => Promise.reject(error.response?.data),
);

export { specialtyInstance, universityInstance };
