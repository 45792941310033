import { createIcon } from '@chakra-ui/icons';

export const GooglePlay = createIcon({
  displayName: 'GooglePlay',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M16.504 2.25H7.5C4.60079 2.25 2.25 4.60079 2.25 7.5V16.504C2.25 19.4012 4.59879 21.75 7.496 21.75H16.504C19.401 21.75 21.75 19.4014 21.75 16.505V7.496C21.75 4.59879 19.4012 2.25 16.504 2.25ZM7.5 3.75H16.504C18.5728 3.75 20.25 5.42721 20.25 7.496V16.505C20.25 18.5729 18.5727 20.25 16.504 20.25H7.496C5.42721 20.25 3.75 18.5728 3.75 16.504V7.5C3.75 5.42921 5.42921 3.75 7.5 3.75ZM6.924 7.48864V16.5116C6.924 17.7438 8.25391 18.5181 9.325 17.9085L14.4207 15.0131C14.4605 14.9949 14.4992 14.973 14.5362 14.9474L17.2635 13.3977C18.3476 12.7825 18.3476 11.2187 17.2632 10.6033L14.5397 9.05514C14.5005 9.02772 14.4595 9.00452 14.4171 8.9855L9.32465 6.09062C8.253 5.48254 6.924 6.25696 6.924 7.48864ZM16.5228 12.0933L14.2789 13.3684L13.122 12.0001L14.2786 10.6322L16.5224 11.9077C16.5944 11.9485 16.5944 12.0527 16.5228 12.0933ZM12.952 14.1223L12.1398 13.1617L9.82542 15.8989L12.952 14.1223ZM12.9519 9.878L12.1398 10.8385L9.82451 8.10019L12.9519 9.878ZM11.1576 12.0001L8.424 8.76702V15.2331L11.1576 12.0001Z"
    />
  ),
});
