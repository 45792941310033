import { IOptions } from '@interfaces/specialty';
import { IFormData } from '@interfaces/user';
import SpecialtyRepository from '@services/api/SpecialtyRepository';
import UniversityRepository from '@services/api/UniversityRepository';
import {
  createContext, MutableRefObject, ReactNode, useContext, useEffect,
  useMemo, useRef, useState, Dispatch, SetStateAction,
} from 'react';
import { DATA } from './useFormData.data';

interface IFormDataContext {
  formData: MutableRefObject<IFormData>
  specialties: IOptions[] | undefined;
  categories: IOptions[] | undefined;
  universities: IOptions[] | undefined;
  getSpecialtyById: (id: string) => void;
  getUniversities: () => void;
  stepper: 'one' | 'two';
  setStepper: Dispatch<SetStateAction<'one' | 'two'>>
}

interface IFormDataProvider {
  children: ReactNode;
}

export const FormDataContext = createContext({} as IFormDataContext);

const FormDataProvider = ({ children }: IFormDataProvider) => {
  const [stepper, setStepper] = useState<IFormDataContext['stepper']>('one');
  const [categories, setCategories] = useState<IOptions[]>();
  const [specialties, setSpecialties] = useState<IOptions[]>();
  const [universities, setUniversities] = useState<IOptions[]>();
  const formData = useRef(DATA);

  const getCategories = () => {
    const results = SpecialtyRepository.get();
    setCategories(results);
  };

  const getSpecialtyById = async (id: string) => {
    const results = await SpecialtyRepository.getSpecialtyById(id);
    setSpecialties(results);
  };

  const getUniversities = async () => {
    const results = await UniversityRepository.get();
    setUniversities(results);
  };

  useEffect(() => {
    getCategories();
  }, []);

  const value = useMemo(() => ({
    formData, categories, getSpecialtyById, specialties, getUniversities, universities, setStepper, stepper,
  }), [specialties, categories, universities, stepper]);

  return (
    <FormDataContext.Provider value={value}>
      {children}
    </FormDataContext.Provider>
  );
};

const useFormData = (): IFormDataContext => {
  const context = useContext(FormDataContext);

  if (!context) {
    throw new Error('useFormData must be used within an FormDataProvider');
  }

  return context;
};

export { useFormData, FormDataProvider };
