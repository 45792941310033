import ContentWrapper from '@components/Template/ContentWrapper';
import HeadingSection from '@components/Template/HeadingSection';
import { useKeycloackContext } from '@hooks/useKeycloakContext';
import {
  Button, Text, Tag, Flex, Box,
} from '@memed/epiderme';
import { KeycloakContextProps } from '@services/keycloak/types/context.type';
import { trackPasswordResetSuccessfullyPageview } from '@services/tracking/x-ray-tracker';
import { KcContextBase } from 'keycloakify';
import { useEffect } from 'react';

const SuccessfulChangePassword = (props: KeycloakContextProps) => {
  const { kcContext } = props;
  const { context: { url } } = useKeycloackContext<KcContextBase.RegisterUserProfile>(kcContext);

  useEffect(() => {
    trackPasswordResetSuccessfullyPageview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentWrapper>
      <HeadingSection title="Redefinição de senha">
        <Tag color="success" iconLeft="info" w="fit-content" size="sm">Senha alterada</Tag>
      </HeadingSection>
      <Text color="neutral.600" mb={8} lineHeight="base">
        A senha foi alterada com sucesso, você já pode acessar sua conta com as novas credenciais.
      </Text>
      <Flex gap={4} justifyContent="center">
        <Box as="a" flex={1} href={url.loginAction}>
          <Button mb={8} w="full">Entrar na Memed</Button>
        </Box>
      </Flex>
    </ContentWrapper>
  );
};

export default SuccessfulChangePassword;
